import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { StylesModule } from './shared/styles/styles.module';
import { PipesModule } from './core/i18n/pipes.module';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';

@Injectable()
export class CustomUrlSerializer implements UrlSerializer {
  constructor(private defaultUrlSerializer: DefaultUrlSerializer) {}

  parse(url: string): UrlTree {
    // Change plus signs to encoded spaces
    url = url.replace(/\+/g, '%20');
    url = url.replace(/\(/g, '%28');
    url = url.replace(/\)/g, '%29');
    // Use the default serializer that you can import to just do the
    // default parsing now that you have fixed the url.
    return this.defaultUrlSerializer.parse(url);
  }

  serialize(tree: UrlTree): string {
    // Custom code here
    return this.defaultUrlSerializer.serialize(tree).replace(/%20/g, '+');
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    CoreModule,
    StylesModule,
    BrowserAnimationsModule,
    HttpClientModule,

    PipesModule,
    NgxWebstorageModule.forRoot({
      prefix: 'RDM',
      separator: '/',
      caseSensitive: true,
    }),
  ],
  providers: [
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    DefaultUrlSerializer,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
