<div class="spinner-box" *ngIf="showSpinner | async">
  <div class="pulse-container">
    <div class="pulse-bubble pulse-bubble-1"></div>
    <div class="pulse-bubble pulse-bubble-2"></div>
    <div class="pulse-bubble pulse-bubble-3"></div>
  </div>
</div>

<div class="screen-box">
  <router-outlet></router-outlet>
  <div class="version-box">Version: {{ env.appVersion }}</div>
</div>
