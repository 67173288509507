import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { dictionaryRoutes } from 'src/app/shared/api/dictionary.api';

export const SUPPORTED_LANGUAGES = [
  { code: 'it', name: 'Italiano' },
  { code: 'en', name: 'English (US)' },
  { code: 'fr', name: 'Français' },
];

export const SUPPORTED_DATEFORMAT = [
  { code: 'dd/MM/yyyy', name: 'DD / MM / YYYY' },
  { code: 'MM/dd/yyyy', name: 'MM / DD / YYYY' },
];

@Injectable({
  providedIn: 'root',
})
export class DictionaryService {
  _keys: string[] = [];
  _values: any[] = [];

  private dictionaryLoaded$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public currentLang: string;
  public currentFormat: string = 'dd/MM/yyyy';

  // public labels: Promise<any>;

  constructor(
    private http: HttpClient,
    private localstorage: LocalStorageService
  ) {
    this.currentLang =
      this.localstorage.retrieve('locale') ??
      navigator?.language?.split('-')[0];

    this.currentFormat =
      localStorage.getItem('date-format') ?? SUPPORTED_DATEFORMAT[0].code;

    this.initDict(this.currentLang);

    // this.getLanguage(String(this.currentLang).toUpperCase());
    // this.labels = this.getLanguage(String(this.currentLang).toUpperCase());
  }

  async getLanguage(lang: string): Promise<any> {
    return this.http
      .request('GET', dictionaryRoutes.getLanguage, { params: { lang } })
      .pipe(map((m) => m))
      .subscribe((response: any) => {
        if (response) {
          for (const key in response.data) {
            if (Object.prototype.hasOwnProperty.call(response.data, key)) {
              (this as any)[key] = response.data[key];
              this._keys.push(key);
              this._values.push(response.data[key]);
            }
          }
        }

        this.dictionaryLoaded$.next(true);
        return { data: response.data };
      });
  }

  initDict(lang: string) {
    let init;

    switch (lang) {
      case 'it':
        init = require('../i18n/dictionary.it-IT.json');
        break;

      case 'en':
        init = require('../i18n/dictionary.en-US.json');
        break;

      case 'fr':
        init = require('../i18n/dictionary.fr-FR.json');
        break;
      case 'de':
        init = require('../i18n/dictionary.de-DE.json');
        break;
      case 'es':
        init = require('../i18n/dictionary.es-ES.json');
        break;
      case 'hu':
        init = require('../i18n/dictionary.hu-CS.json');
        break;
      case 'pl':
        init = require('../i18n/dictionary.pl-PL.json');
        break;
      case 'pt':
        init = require('../i18n/dictionary.pt-BR.json');
        break;
      case 'ru':
        init = require('../i18n/dictionary.ru-RU.json');
        break;
      case 'sk':
        init = require('../i18n/dictionary.sk-SK.json');
        break;
      case 'sr':
        init = require('../i18n/dictionary.sr-CS.json');
        break;
      default:
        init = require('../i18n/dictionary.en-US.json');
        break;
    }

    if (init) {
      for (let x = 0; x < init.length; x++) {
        const currKey = Object.keys(init[x])[0];
        (this as any)[currKey] = init[x][currKey];

        this._keys.push(currKey);
        this._values.push(init[x][currKey]);
      }
    }
  }

  changeLang(newLang: string): void {
    if (SUPPORTED_LANGUAGES.findIndex((f) => f.code === newLang) === -1) return;
    else {
      this.currentLang = newLang;
      this.localstorage.store('locale', newLang);
    }
  }

  changeFormat(newFormat: string): void {
    if (SUPPORTED_DATEFORMAT.findIndex((f) => f.code === newFormat) === -1)
      return;
    else {
      this.currentFormat = newFormat;
      this.localstorage.store('date-format', newFormat);
    }
  }

  add(key: string, value: any) {
    (this as any)[key] = value;

    this._keys.push(key);
    this._values.push(value);
  }

  remove(key: string) {
    const index = this._keys.indexOf(key, 0);
    this._keys.splice(index, 1);
    this._values.splice(index, 1);

    delete (this as any)[key];
  }

  keys(): string[] {
    return this._keys;
  }

  values(): any[] {
    return this._values;
  }

  containsKey(key: string) {
    return typeof (this as any)[key] !== 'undefined';
  }

  getValue(key: string): string {
    return (this as any)[key] || key;
  }
}
