import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DictionaryService } from './dictionary.service';

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  private spinnerCounter = 0;

  public source?: any;
  public userid?: any;
  public fileid?: any;

  public displaySpinner$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private snackbar: MatSnackBar,
    private dictionary: DictionaryService,
    private localstorage: LocalStorageService
  ) {
    const rememberMe = this.localstorage.retrieve('remember') === 'true';

    if (rememberMe) {
      this.userid = this.localstorage.retrieve('userid');
    }
  }

  makeCheckRequest(): Observable<any> {
    if (this.userid && this.source) {
      const FINAL_URL = environment.BASE_URL.replace(
        '{{userIdentifier}}',
        this.userid.toLowerCase()
      ).replace('{{sourceSystem}}', this.source);

      return this.http.get(FINAL_URL).pipe(map((m) => m));
    } else return of([false]);
  }

  fileRetrieveRequest(fileId: string, email: string): any {
    if (this.userid) {
      const FINAL_URL = environment.FILE_URL.replace(
        '{{userIdentifier}}',
        email
      ).replace('{{fileId}}', fileId);

      return this.http
        .get(FINAL_URL, { responseType: 'blob' })
        .pipe(map((m) => m));
    }
  }

  // SPINNER
  setSpinnerStatus(showSpinner: boolean) {
    if (showSpinner) {
      this.spinnerCounter++;
    } else {
      this.spinnerCounter--;
    }

    if (this.spinnerCounter === 0) {
      this.displaySpinner$.next(false);
    } else {
      this.displaySpinner$.next(true);
    }
  }

  // TOAST
  showMessage(
    messageKey: string = 'data_updated_key',
    status: 'SUCCESS' | 'ERROR' | 'WARN' | 'INFO' = 'INFO'
  ) {
    const message = this.dictionary.getValue(messageKey).toUpperCase();

    this.snackbar.open(message, status, {
      duration: 5000,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      panelClass:
        status === 'SUCCESS'
          ? 'snackbar-class-success'
          : status === 'ERROR'
          ? 'snackbar-class-error'
          : status === 'WARN'
          ? 'snackbar-class-warning'
          : 'snackbar-class-info',
    });
  }
}
