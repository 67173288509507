import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { AppStateService } from './core/services/app-state.service';
import { DictionaryService } from './core/services/dictionary.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  alive = true;
  env = environment;

  showSpinner: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(
    private appState: AppStateService,
    private dictionary: DictionaryService,
    private activatedRoute: ActivatedRoute
  ) {
    this.appState.displaySpinner$
      .pipe(takeWhile(() => this.alive))
      .subscribe((status: boolean) => this.showSpinner.next(!!status));

    this.activatedRoute.queryParams.subscribe((params) => {
      const { sourceSystem, source_system } = params;
      const sSystem = sourceSystem || source_system;

      this.appState.source = sSystem;
    });
  }

  ngOnInit(): void {
    this.alive = true;
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}
