// ************************************************** //
// ***               PWA Service                  *** //
// *** Useful for interaction with service worker *** //
// *** and software update                        *** //
// ************************************************** //

import { Injectable } from '@angular/core';
import { DictionaryService } from './dictionary.service';

@Injectable()
export class PwaService {
  public promptEvent: any;

  constructor(
    // private swUpdate: SwUpdate,
    private dictionary: DictionaryService
  ) {
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
      this.promptEvent = event;
    });
  }
}
