import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModuleWithProviders } from '@angular/compiler/src/core';
import { SocketService } from './services/socket.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PipesModule } from './i18n/pipes.module';
import { AppStateService } from './services/app-state.service';
import { PwaService } from './services/pwa.service';

export const NB_CORE_PROVIDERS = [
  AppStateService,

  SocketService,
  PwaService,
  //
  FontAwesomeModule,
];

@NgModule({
  imports: [CommonModule, PipesModule],
  exports: [PipesModule],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: CoreModule,
      providers: NB_CORE_PROVIDERS,
    };
  }
}

export function throwIfAlreadyLoaded(parentModule: any, moduleName: string) {
  if (parentModule) {
    throw new Error(
      `${moduleName} has already been loaded. Import Core modules in the AppModule only.`
    );
  }
}
