import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from './translate.pipe';
import { DictionaryService } from '../services/dictionary.service';

@NgModule({
  imports: [CommonModule],
  declarations: [TranslatePipe],
  providers: [DictionaryService],
  exports: [TranslatePipe],
})
export class PipesModule {}
