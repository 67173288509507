// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: require('../../package.json').version + '-dev',

  BASE_URL:
    'https://api.test.la-login.stellantis.com/user/data?userIdentifier={{userIdentifier}}&sourceSystem={{sourceSystem}}',

  FILE_URL:
    'https://api.test.la-login.stellantis.com/user/file?userIdentifier={{userIdentifier}}&file={{fileId}}',

  CAPTCHA: '6Lftn3gfAAAAAFyOqhxk4l0S0JC4YJhKQIL30OzR',
};
