import { Pipe, PipeTransform } from '@angular/core';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { DictionaryService } from '../services/dictionary.service';

@Pipe({
  name: 'translate',
})
export class TranslatePipe implements PipeTransform {
  private unsubscribe$: Subject<boolean> = new Subject();

  constructor(private dictionary: DictionaryService) {}

  transform(key: any, ...args: any[]) {
    return this.dictionary.getValue(key);
  }
}
